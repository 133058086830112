
import { Component, Vue } from 'vue-property-decorator';
import Grid from '@/components/grid/Grid';
import GridCell from '@/components/grid/GridCell';
import FullScreenForm from '@/components/FullScreenForm.vue';
import { RawLocation } from 'vue-router';
import { RouteNames } from '@/constants';
import { RpaOpsModule } from '@/features/om27/store/om27.module';
import { UserPreferences, SearchRecord, SearchConfig } from '@/features/om27/types/om27.types';
import DataGrid from '@/features/om27/components/DataGrid.vue';
import UnassignedBotsGrid from '@/features/om27/components/UnassignedBotsGrid.vue';
import { Promised } from 'vue-promised';
import BotSearch from '@/features/om27/components/BotSearch.vue';
import Fuse from 'fuse.js';
import { getQuery } from '@/utils/routing';

@Component({
  components: {
    Grid,
    GridCell,
    FullScreenForm,
    DataGrid,
    Promised,
    BotSearch,
    UnassignedBotsGrid,
  },
})
export default class Search extends Vue {

  searchConfig: Record<string, SearchConfig> = {
    [RouteNames.Om27]: {
      name: 'Bots',
      routeName: RouteNames.Om27 as string,
      storeData: () => {
        return RpaOpsModule.logicalBots;
      },
      searchKeys: [
        'botName',
        'folderName',
        'assignedUsers',
        'blockingTickets.ticketNumber',
        'soxDescription',
        'soxDocumentReference',
        'soxHumanApprover',
        'subRecords.botRunnerUserId',
        'subRecords.machineNameShort',
        'subRecords.fileName',
      ],
      promise() {
        if (!RpaOpsModule.logicalBots.length) {
          return RpaOpsModule.getDashboard();
        }
        return Promise.resolve();
      }
    },
    [RouteNames.RpaOpsUnassignedBots]: {
      name: 'Unassigned Bots',
      routeName: RouteNames.RpaOpsUnassignedBots as string,
      storeData: () => {
        return RpaOpsModule.unassignedBots;
      },
      searchKeys: [
        'botName',
        'host',
        'hostShort',
        'botRunnerId',
        'paths',
      ],
      promise() {
        if (!RpaOpsModule.unassignedBots.length) {
          return RpaOpsModule.getUnassignedBots();
        }
        return Promise.resolve();
      }
    },
  };

  closeRoute: RawLocation = {
    name: RouteNames.Om27,
  };

  get timezone(): UserPreferences['preferred_timezone'] {
    return RpaOpsModule.userPreferences.preferred_timezone;
  }

  get showActionNeededOnly(): boolean {
    return RpaOpsModule.showActionNeededOnly;
  }

  get bots(): SearchRecord[] {
    const searchConfig = this.getSearchConfig();
    return searchConfig.storeData();
  }

  get searchResults(): SearchRecord[] {
    const q = getQuery(this, 'search');
    if (q && this.fuse) {
      const result = this.fuse.search(q);
      const b = result.map(r => r.item);
      return b;
    } else {
      return this.bots;
    }
  }

  get selectedBots(): number[] {
    return [];
  }

  getSearchConfig(): SearchConfig {
    const searchTarget = getQuery(this, 'searchTarget') || RouteNames.Om27;
    return this.searchConfig[searchTarget];
  }

  get fuse() {
    console.log('get fuse');
    const searchScope = getQuery(this, 'searchScope');
    const fuseOptions = {
      findAllMatches: true,
      includeScore: true,
      includeMatches: true,
      ignoreLocation: true,
      threshold: 0,
    };
    if (!searchScope || searchScope === 'global') {
      const searchConfig = this.getSearchConfig();
      return new Fuse(this.bots, {
        ...fuseOptions,
        keys: searchConfig.searchKeys,
      });
    } else {
      return new Fuse(this.bots, {
        ...fuseOptions,
        keys: [searchScope],
      });
    }
  }

  promise: Promise<unknown> | null = null;
  searchTarget: string = RouteNames.Om27;
  async created(): Promise<void> {
    const searchConfig = this.getSearchConfig();
    this.searchTarget = searchConfig.routeName;
    this.closeRoute = {
      name: searchConfig.routeName
    };

    this.promise = searchConfig.promise();
    await this.promise;

  }
}
